.input {
  width: 100%;

  :global {
    .MuiOutlinedInput-root.MuiInputBase-formControl {
      height: 38px;
    }
    input {
      font-weight: 500;
      font-size: 14px;
    }
    fieldset {
      border-color: var(--color-ash);
    }
    .MuiInputLabel-root {
      opacity: 0 !important;
    }

    
    // .MuiClockPicker-root .MuiButtonBase-root {
    //   background-color: var(--color-purple) !important;
    // }
    .PrivateNotchedOutline-legendLabelled-3 {
      display: none !important;
    }

  }
}

.buttonSky {
  color: var(--color-sky);
}

.timeRangePickerHorizontal {
  display: grid;
  grid-template-columns: 5fr 1fr 5fr;
  align-items: center;
  gap:1em;
}

.timeRangePickerVertical {
  display: flex;
  flex-direction: column;
  gap:1em;
}