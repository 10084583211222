.tabs {
  display: grid;
  gap: 30px;
  margin: 22px 0px;
  .active {
    border: solid 1px #5349b7;
  }

  > div {
    cursor: pointer;
    transition: 0.2 all;
    border: solid 1px transparent;
    padding: 16px 16px 12px 18px;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 #e8e7eb;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div:first-child {
        display: flex;
        align-items: center;
        gap: 2px;
        :first-child {
          font-family: Roboto;
          font-size: 36px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.83;
          letter-spacing: normal;
          text-align: left;
          color: #34384b;
        }
        > :nth-child(2) {
          margin-top: 1.2em;
        }
      }
    }
  }
}
