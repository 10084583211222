.searchIcon {
  color: var(--color-cement);
}

.clearIcon {
  color: var(--color-indigo);
  font-size: 13px;
  cursor: pointer;
}

.searchFieldV2 {
  position: relative;
  width: 100%;
  .suggestion {
    margin-top: 1em;
    width: 100%;
    position: absolute;
    background-color: white !important;
    z-index: 10;
    border-radius: 10px;
    border: 1px solid rgb(215, 215, 215);
    padding: 15px 0px;
    > div {
      padding: 7px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
      > div {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
      }

      > i {
        margin-right: 1em;
        font-size: 12px;
        color: var(--color-cement);
      }
    }

    > div:hover {
      background-color: var(--color-baby-blue);
    }
  }
}
