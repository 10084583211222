.row {
  display: flex;
  margin: 20px 0px;
  align-items: center;

  .fit {
    margin: 0px;
  }

  .center {
    align-items: center;
  }
}

.column {
  float: left;
}

.userForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  align-items: center;
  gap: 1em;
  border-radius: 4px;
  border: solid 1px #ebeef7;
  background-color: #f7f8fa;
  padding-left: 2em;
  padding-right: 0.5em;
}

.userList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  align-items: center;
  gap: 1em;
  padding-left: 2em;
  padding-right: 0.5em;
  border-bottom: 2px solid #ebeef7;

  > div {
    padding: 1em;
    color: var(--color-night);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;

    .circleIcon {
      position: absolute;
      right: 0;
      top: 0;
      margin-right: -10px;
      margin-top: -10px;
      background-color: white;
    }
  }
}

.container {
  width: 100%;
  margin: 20px 0px;

  .plainTable {
    table {
      > thead {
        > tr:first-child th:first-child {
          border-left: none;
        }

        > tr:first-child th:last-child {
          border-right: none;
        }
      }

      > tbody {
        > tr:first-child > td {
          border-top: none;
        }
        > tr > td:first-child {
          border-left: none;
        }
        > tr > td {
          border-bottom: none;
        }
        > tr > td:last-child {
          border-right: none;
        }
      }
    }

    table tbody {
      display: block;
      overflow-y: scroll;
    }

    table tbody::-webkit-scrollbar {
      width: 5px;
      border-radius: 40%;
    }

    table tbody::-webkit-scrollbar-thumb {
      background: var(--color-cement);
      border-radius: 5px;
    }

    table th {
      margin: 39px 21px 22px 108px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #a9acc6;
    }

    table tbody td {
      div > div:not(b) {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #34384b;
      }
    }

    table thead,
    table tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      border: none;
    }
  }
}

.tableContainer {
  background-color: #f7f8fa;
  border: 1px solid var(--color-ash);
  border-radius: 10px;
}

.viewUGTsTable {
  @extend .tableContainer;
  width: 600px;
}

.viewUsersTable {
  @extend .tableContainer;
  width: 700px;
}

.viewDryStocksTable {
  @extend .tableContainer;
  width: 800px;
}

.viewPromosTable {
  @extend .tableContainer;
  width: 65%;
}

.inputPromosTable {
  width: 65%;
}

:global {
  .MuiAccordionDetails-root {
    display: block;
  }
}

.section {
  width: inherit;
  padding: 20px;
  border-radius: 20px;
}

.menu {
  margin-bottom: 40px;
  background-color: #fafafa;
  border: 1px solid #ededed;
}

.entry {
  margin-top: 20px;
  border-radius: 0px;
  padding: 0px 20px;
  border-style: none;
  height: inherit;

  .values {
    padding-left: 20px;
    padding-bottom: 10px;
    color: #212529 !important;
    font-weight: 500;
  }

  .border {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-gray);
  }

  .product {
    margin: 0px;
  }

  .keyAccountBorder {
    width: 92%;
  }

  .display {
    font-weight: 500;
    width: 100px;
  }
}

.spacing {
  width: 20px;
}

.fieldSpacing {
  width: 31%;
}

.buttonSpacing {
  width: 7%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.subtitle {
  font-size: 16px !important;
  margin-bottom: 18px;
  color: var(--color-night);
}

.shortLabel {
  color: #212529 !important;
  width: 100px;
  font-weight: 500;
}

.midLabel {
  color: #212529 !important;
  width: 150px;
  font-weight: 500;
}

.label {
  color: #212529 !important;
  width: 300px;
  font-weight: 500;
}

.labelCement {
  color: var(--color-cement) !important;
  width: 300px;
  font-weight: 500;
}

.labelLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.userContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-radius: 4px;
  border: solid 1px #ebeef7;
  background-color: #f7f8fa;
  position: relative;
  .circleIcon {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: -10px;
    margin-top: -10px;
    background-color: white;
  }
}

.circleIcon {
  margin-top: 7px;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  color: var(--color-purple-variant);
  border: 1px solid var(--color-purple-variant);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.labelGray {
  color: var(--color-gray);
  width: 310px;
}

.ugtMargin {
  margin: 5px 0px 0px 150px;
  width: 160px;
}

.fieldContent {
  width: 290px;
  img {
    margin-left: -1em;
  }
}

.shortContent {
  width: 150px;
}

.longContent {
  width: 400px;
}

.productInput {
  display: grid;
  grid-template-columns: 1.15fr 0.7fr 1fr;
  align-items: center;
  gap: 1em;
  min-width: auto !important;
  justify-content: space-between;
}

.close {
  background: none;
  border: 1px solid var(--color-gray);
  color: var(--color-purple-variant);
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 3px;
  font-weight: 900;
  .icon {
    font-weight: 900;
    margin: auto;
    transition: font-size 0.1s;
    color: var(--color-night);
  }

  &:hover {
    .icon {
      font-size: 10.5px;
    }
  }
}

.button {
  color: var(--color-purple-variant);
  border: 1px solid var(--color-purple-variant);
  font-weight: bold;
  font-size: 14px;
}

.flexCol {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.labelContainer {
  background: var(--color-whitesmoke);
  height: 38px;
  margin-right: -13px;
  border-radius: 3px 0px 0px 3px;
  border-right: 1px solid var(--color-ash);
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-night);
}

.selectStationsActions {
  display: flex;
  gap: 1em;
  align-items: center;
}

.textNight {
  color: var(--color-night);
}

.popOver {
  max-height: 160px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.viewPromoParticipatingStationContainer {
  display: flex;
  align-items: center;
  gap: 3em;
}

.contentAddPromoModal {
  @extend .textNight;
  width: 254px;
  height: 39px;
  margin: 37px 63px 29px 77px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
}

.contentLeavePageModal {
  @extend .textNight;
  width: 360px;
  height: 39px;
  margin: 37px 63px 29px 77px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
}

.multilineField {
  display: flex;
  align-items: flex-start;
}

.contentLeavePageModal {
  @extend .textNight;
  width: 360px;
  height: 39px;
  margin: 37px 63px 29px 77px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
}
