.station {
  width: 650px !important;
} 

.promo {
  width: 700px !important;
}

.modal {
  width: 640px;
  //  transform-origin: center;
  //  position: absolute;
  //  animation: popIn 0.5s ease forwards;

  //  @keyframes popIn {
  //     0% {
  //       transform: scale(0.1);
  //       top: 100%;
  //       left: 40%;
  //       opacity: 0;
  //     }
  //     100% {
  //       transform:  scale(1);
  //       top: 10%;
  //       left: 40%;
  //       opacity: 1;
  //     }
  //   }

  @media (max-height: 712px) {
    // Your styles here
    table tbody {
      display: block;
      max-height: 30vh !important;
      overflow-y: scroll;
    }
  }

  table tbody {
    display: block;
    max-height: 40vh;
    overflow-y: scroll;
  }

  table tbody::-webkit-scrollbar {
    width: 5px;
    border-radius: 40%;
  }

  table tbody::-webkit-scrollbar-thumb {
    background: var(--color-cement);
    border-radius: 5px;
  }

  table thead,
  table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldContent {
  width: 290px;
  img {
    margin-left: -1em;
  }
}

.date {
  position: relative;
  width: 236px;
}

.fieldSelectAll {
  background-color: var(--color-light-grey);
  label {
    font-weight: bold;
    color: var(--color-cement);
  }
}

.search {
  width: 100%;
  margin-left: 10px;
}

.clearSelectedButton {
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;
  }

  span {
    margin-top: 2px;
  }
}
