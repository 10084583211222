.quantitySelector {
    width: 150px;
    input {
        text-align: center;
    }
}

.labelContainer {
    background: var(--color-whitesmoke);
    height: 38px;
    margin-left: -13px;
    border-radius: 3px 0px 0px 3px;
    border-right: 1px solid var(--color-ash);
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-night);
    cursor: pointer;
    transition: transform 0.1s all;

    &:hover {
        transform: scale(1.09);
    }
  }
  
  .suffix {
    background: var(--color-whitesmoke);
    height: 38px;
    border-right: 1px solid var(--color-ash);
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-night);
    margin-right: -13px;
    cursor: pointer;
    transition: transform 0.1s all;

    &:hover {
        transform: scale(1.09);
    }
  }
  
  .disabled {
    color: var(--color-cement);
  }
  