.table {
  table {
    width: 100%;
  }
}

.td {
  color: black;
}

.subtitle {
  color: darkgray;
  margin-top: 5px;
}

.textNight {
  color: black;
}
