.custom{
    background-color: red;
    box-shadow: none;
}

.heading {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}