.popoverContainer {
  :global {
    .MuiPaper-root {
      // margin-left: 70px;
      margin-left: -3em !important;
      overflow: initial;
      background: transparent;
      width: auto;
      margin-top: -4em;
      height: fit-content;
      position: relative;
      box-shadow: none;
    }
  }
  max-height: 200px;
  width: 300px;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 40%;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--color-cement);
    border-radius: 5px;
  }

  table td div {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}

.arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  right: 0;
  background: #ffffff !important;
  top: 3.5em !important;
  margin-right: 5px !important;
  transform: rotate(45deg);
  border-radius: 2px;
}

.arrowRight {
  position: absolute;
  width: 10px;
  height: 10px;
  left: 0;
  background: #ffffff !important;
  top: 50px;
  margin-left: 5px;
  transform: rotate(45deg);
  border-radius: 2px;
}

.content {
  font-size: 14px;
  padding: 12px 23px;
  background: var(--color-white);
  border-radius: 4px;
  max-height: 200px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) !important;
  z-index: 10 !important;
  padding-left: 10px;
  padding-right: 1px;
  margin: 10px;
  max-height: 186px;
  overflow: scroll;
}

.icon {
  color: var(--color-night);
}
