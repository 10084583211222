.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.fieldContent {
  width: 331px;
}

.reasonRemarks {
  display: flex;
  align-items: flex-start;
  gap: 1em;

  div:nth-child(2) {
    flex: auto;
  }
}

.textNight {
  color: var(--color-night);
}

.titleStyle {
  @extend .textNight;
}

.contentStyle {
  @extend .textNight;
  width: 332px;
}

.subtitle {
  @extend .contentStyle;
  margin: auto;
  padding: 10px;
  text-align: center;
}
